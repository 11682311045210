
html {
  scroll-behavior: smooth;
}


body {
  font-family: 'Inter', sans-serif;
  padding: 0px;
  margin: 0px;

  
}

h1, h2, h3, h4, h5, h6, button {
  font-family: 'Montserrat', sans-serif;
}

p{
  font-size: 16;
  font-family: 'Inter', sans-serif;
}

