.cookie-popup {
  position: fixed;
  bottom: 0;
  left: 10;
  background-color: #333333de;
  color: #fff;
  font-size: 14px;
  z-index: 9999;
  transform: translateY(100%);
  transition: transform 0.6s ease-in-out;
  max-width: 370px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;


  margin: auto;

 
}
.paragraf {
  padding: 25px;
}


.style-barra {    
  width: 100%;
  height: 3px;
  background-color: 007F9B;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #007F9B;
  position: absolute;
}

.cookie-popup.visible {
  transform: translateY(0);
}

.cookie-popup p {
  margin: 0 0 10px;
}

.button-active {
  background-color: #007F9B;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  min-width: 300px;
  justify-content: center;
}

.button-active:hover {
  background-color: #11922d; 
}

.button-cancelar {
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  min-width: 300px;
  justify-content: center;
  background-color: #000000; 
  color: #ffffff;
}

.button-cancelar:hover {
  background-color: #e8e8e8;
  color: #007F9B;
}



